<template>

    <div class="w-full p-1" :class="{'h-52': mobile, 'h-full': !mobile}">

        <div class="h-full w-full rounded-lg flex flex-col shadow_box" :class="'bg-box-'+mode">

            <div class="h-auto flex flex-row justify-start items-center p-2">
                    <span class="font-medium text-sm" :class="'text-dfont-'+mode">{{ $t('orders') }}</span>
                    <span class="text-2xs ml-auto" :class="'text-font_gray-'+mode">{{ checkDate(lastSale) }}</span>
                </div>

            <div class="h-40 flex flex-col justify-center items-center">

                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('orders')}} {{$t('day')}}</span>
                <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Day | reduceBigNumbers(2)}} €</span>
                <span class="text-sm font-medium mb-2" :class="'text-dfont-'+mode">{{data.DayMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('orderBook')}}</span>
                <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Accumulated | reduceBigNumbers(2)}} €</span>
                <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.AccumulatedMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>

            </div>

            <div class="mt-auto text-2xs text-center mb-5"  :class="'text-font_gray-'+mode">
                <!-- Última sincronización <strong>{{ data.SyncTime | moment('HH:mm') }}</strong> -->
            </div>

        </div>

    </div>



</template>

<script>
    import singlesolidgauge from './singlesolidgauge'
    import { state, actions } from '@/store';
    export default {
        props:['data', 'lastSale'],
        components:{
            singlesolidgauge
        },
        data(){
            return{
                open: false,
                datasolidgauge:{
                    actual: 0,
                    prev: 0
                }
            }
        },
        methods:{
            checkDate(date){

                if(state.period == 'month'){

                    if(this.$moment(new Date(date)).format('M') == this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')){

                        return this.$moment(new Date(date)).format('DD MMM')

                    } else if(parseInt(this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')) > this.$moment(new Date(date)).format('M')){

                        return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('MMM')

                    }else{

                        return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).endOf('month').format('DD MMM')

                    }

                }

                if(state.period == 'quarter'){

                    if(this.$moment(new Date(date)).format('Q') == this.$moment(new Date()).format('Q')){
                        return this.$moment(new Date(date)).format('DD MMM')
                    } else {
                        return this.$moment(new Date(date)).endOf('quarter').format('DD MMM')
                    }

                }

            }
        },
        computed:{
            periodfiltered(){
                switch (state.period) {
                    case 'month':
                        return this.$moment().format("MMM 'YY")
                        break;

                    case 'quarter':
                        return 'Trim.' + this.$moment().format("Q 'YY")
                        break;

                    case 'year':
                        return this.$moment().format("YYYY")
                        break;
                }
            },
            yesterday(){
                return this.lastSale.format("DD MMM");
            },
            mode(){
                return state.mode
            },
            mobile(){
                return state.isMobile
            }
        }
    }
</script>
