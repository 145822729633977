<template>

    <div class="h-full">

        <div class="w-full p-1" :class="{'h-52': mobile, 'h-full': !mobile}">

            <div class="h-full w-full rounded-lg flex flex-col shadow_box" :class="'bg-box-'+mode">

                <div class="h-auto flex flex-row justify-start items-center p-2">
                        <span class="font-medium text-sm" :class="'text-dfont-'+mode">{{ $t('deliveryNotes') }}</span>
                        <span class="text-2xs ml-auto capitalize" :class="'text-font_gray-'+mode">{{ $t('today') }}</span>
                    </div>

                <div class="flex flex-1 min-h-0 flex-col justify-center items-center leading-none">

                    <img src="/img/albaranes.svg" class="h-8">
                    <span class="text-xs my-2" :class="'text-font_gray-'+mode">{{$t('current')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Day | reduceBigNumbers(2)}} €</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.DayMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
                
                </div>

                <div class="mt-auto text-2xs text-center mb-1">
                    <span :class="'text-dfont-'+mode">{{$t('lastSynchronization')}} <strong :class="'text-dfont-'+mode">{{ data.SyncTime | moment('HH:mm') }}</strong></span>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
import singlesolidgauge from './singlesolidgauge';
import { state } from '@/store';

export default {
    props:['data'],
    components:{
        singlesolidgauge
    },
    data(){
        return{
            datasolidgauge:{
                actual: 0,
                prev: 0
            }
        }
    },
    computed:{
        mode(){
            return state.mode
        },
        mobile(){
            return state.isMobile
        }
    }
}
</script>
