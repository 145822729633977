<template>
    <highcharts class="chart h-full w-full chart-wrap" :options="options" ref="chartId" id="chartId"></highcharts>
</template>

<script>
    export default {
        props: ['data'],
        data: function(){
            return {
                chart: null
            }
        },
        computed: {
            options() {
                return {
                    chart: {
                        type: 'solidgauge',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        margin:[0,0,0,0]
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        enabled: false
                    },
                    credits:{
                        enabled: false
                    },
                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ 
                            outerRadius: '99%',
                            innerRadius: '92%',
                            borderWidth: 0,
                            backgroundColor: '#F0EFEF'
                        }]
                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: []
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true,
                            sliceOffset:0
                        }
                    },
                    series: [
                        {
                            name: 'a',
                            data: [ {
                                color: (this.data.actual > this.data.prev) ? '#7beeb3' : '#DB383F',
                                radius: '99%',
                                innerRadius: '92%',
                                y: (this.data.actual > this.data.prev) ? this.data.actual : this.data.prev
                                //y: 0
                            },{
                                color: '#F8F8F8',
                                radius: '100%',
                                innerRadius: '91%',
                                y: (this.data.actual > this.data.prev) ?  this.data.prev+.3 : this.data.actual+.3
                                //y: 0
                            },
                            {
                                color: '#7070F0',
                                radius: '99%',
                                innerRadius: '92%',
                                y: (this.data.actual > this.data.prev) ? this.data.prev : this.data.actual
                            }]
                        }
                    ],
                    responsive: {

                    }
                }
            }
        },
        updated() {

            const chartI = this.$refs.chartId;
            const chart = chartI.chart;

            setTimeout(() => {
                //chart.update();
                //chart.redraw();
                //chart.reflow();
                //chart.setSize(null, null);
            }, 100);


        }
    }
</script>
<style>
    .chart-wrap{
        height: 100% !important;
        width: 100% !important;
    }
     .chart-wrap svg{
        height: 100% !important;
        width: 100% !important;
    }
    .chart-wrap .highcharts-container{
        height: 100% !important;
        width: 100% !important;
    }
    .chart-wrap>div{
        height: 100% !important;
        width: 100% !important;
    }
</style>