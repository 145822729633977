<template>

    <div class="h-auto w-full">

        <div v-for="(el, index2) in data" :key="index2" class="h-auto w-full mb-4 rounded-xl flex flex-col overflow-hidden shadow_module" 
        :class="'bg-module-'+mode">
        
            <div class="h-12 flex flex-row justify-start items-center px-4 cursor-pointer" :class="'bg-box-'+mode" @click="goToCompany(index, el.Name, el.IndustrialGroup)">
            
                <span class="text-lg font-bold" :class="'text-dfont-'+mode">{{ el.Name }}</span>

                <i class="mdi mdi-arrow-right text-2xl text-red ml-auto"></i>
                
            </div>

            <div class="h-auto w-full px-2 py-2 cursor-pointer" @click="goToCompany(index, el.Name, el.IndustrialGroup)">

                <All model="Groupdimensions" :query="query" :immediate="true" v-slot="{data: data3,loading: loading3}">

                    <div v-if="!loading3" class="h-auto w-full">

                        <div v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                            class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('day') }}</span>
                            <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ el.Yesterday | reduceBigNumbers() }}
                                <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Margen'">%</span>
                            </span>
                        </div>

                        <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                            <span v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                                class="text-xs" :class="'text-font_gray-'+mode">{{ $t('accumulated') }}</span>
                            <span v-if="data3[dimension].Name === 'Precio medio'" class="text-xs" :class="'text-font_gray-'+mode">{{ $t('averagePrice') }}</span>
                            <span v-if="data3[dimension].Name === 'Margen'" class="text-xs" :class="'text-font_gray-'+mode">{{ $t('margin') }}</span>
                            <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ el.Month | reduceBigNumbers() }}
                                <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Margen'">%</span>
                            </span>
                        </div>

                        <div v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                            class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('deliveryNotes' )}}</span>
                            <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ el.DeliveryNote | reduceBigNumbers() }}
                                <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Margen'">%</span>
                            </span>
                        </div>

                        <div v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                            class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('scope') }}</span>
                            <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ el.MonthPreview | reduceBigNumbers() }}
                                <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Margen'">%</span>
                            </span>
                        </div>

                        <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('real') }} {{ prevPeriod(infodata.LastSaleDate) }}</span>
                            <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ el.PastYear | reduceBigNumbers() }}
                                <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                <span v-if="data3[dimension].Name === 'Margen'">%</span>
                            </span>
                        </div>

                        <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-1">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('variation') }} {{ periodo }}</span>
                            <span v-if="data3[dimension].Name === 'Precio medio'" class="text-sm font-medium" :class="'text-dfont-'+mode">
                                <span v-if="el.MonthVariation >= 0">+</span>{{ el.MonthVariation | reduceBigNumbers(2) }} €/m<sup>2</sup>
                            </span>
                            <span v-else-if="data3[dimension].Name === 'Margen'" class="text-sm font-medium" :class="'text-dfont-'+mode">
                                <span v-if="el.MonthVariation >= 0">+</span>{{ el.MonthVariation | reduceBigNumbers(2) }}
                            </span>
                            <span v-else class="text-sm font-medium"  :class="'text-dfont-'+mode"><span v-if="el.MonthVariation >= 0">+</span>
                                {{ el.MonthVariation | reduceBigNumbers(2) }} %
                            </span>
                        </div>

                    </div>

                </All>

            </div>

            <div v-if="permisions" class="h-auto">
                
                <Request model="Grouptocompanymessage" action="create" :form="form" @success="onSuccess" @error="onError" v-slot="{ request , loading:loadingrequest }" class="w-full">
                    
                    <div v-if="!loadingrequest" class="h-auto">

                        <div v-if="user.email === 'admin@mail.zeus.vision' && rol === 'Propiedad'" class="h-10 mb-2 flex flex-row justify-start items-center px-4">
                            <div class="h-8 flex-1 pr-2" @keypress.enter="request">
                                <input ref="input" type="text" v-model="message" :placeholder="$t('writeMessage')"
                                    class="w-full h-full rounded-lg px-2 text-xs" :class="'bg-box-'+mode + ' text-dfont-'+mode">
                            </div>
                            <div v-if="!message || message == ''" class="h-8 w-10 rounded-lg flex flex-row justify-center items-center bg-opacity-50" :class="'bg-font_gray-'+mode">
                                <i class="mdi mdi-send text-white text-xl"></i>
                            </div>
                            <div v-else @click="request" class="h-8 w-10 rounded-lg flex flex-row justify-center items-center bg-navy">
                                <i class="mdi mdi-send text-white text-xl"></i>
                            </div>
                        </div>
                        
                    </div>

                </Request>

            </div>
        
        </div>

    </div>

    <!-- <div class="h-auto w-full cursor-pointer bg-transparent shadow_box flex flex-col overflow-hidden mb-4 xl:w-1/3 xl:float-left xl:p-2 xl:shadow-none">

        <div class="h-auto shadow-card rounded-lg overflow-hidden" >
            <div class="h-auto flex flex-col">

                <div  @click="goToCompany(index, data[0].Name, data[0].IndustrialGroup)" class="h-16 flex flex-col px-4 py-2">

                    <div class="h-full flex flex-row">

                        <div class="h-full w-11/12 flex flex-col justify-center items-start truncate ">
                            <span class="text-xl font-semibold truncate" :class="{
                                'text-pamesa-whitemode':( data[0].Id === '45' ||  data[0].Id === '1') && mode == 'whitemode',
                                'text-pamesa-darkmode': ( data[0].Id === '45' ||  data[0].Id === '1') && mode == 'darkmode',
                                'text-geotiles': data[0].Id === '28', 
                                'text-prissmacer': data[0].Id === '30',
                                'text-ecoceramic': data[0].Id === '31', 
                                'text-dfont-whitemode': data[0].Id === '32' && mode == 'whitemode', 
                                'text-dfont-darkmode': data[0].Id === '32' && mode == 'darkmode', 
                                'text-tauceramica': data[0].Id === '36',
                                'text-ascale': data[0].Id === '64'
                            }">{{ data[0].Name }} </span>
                        </div>

                        <div class="h-full w-1/12 flex flex-row justify-center items-center">
                            <i class="mdi mdi-arrow-right text-2xl" :class="{
                                'text-pamesa-whitemode': ( data[0].Id === '45' ||  data[0].Id === '1') && mode == 'whitemode',
                                'text-pamesa-darkmode': ( data[0].Id === '45' ||  data[0].Id === '1') && mode == 'darkmode',
                                'text-geotiles': data[0].Id === '28', 
                                'text-prissmacer': data[0].Id === '30',
                                'text-ecoceramic': data[0].Id === '31', 
                                'text-dfont-whitemode': data[0].Id === '32' && mode == 'whitemode', 
                                'text-dfont-darkmode': data[0].Id === '32' && mode == 'darkmode', 
                                'text-tauceramica': data[0].Id === '36',
                                'text-ascale': data[0].Id === '64'
                            }"></i>
                        </div>

                    </div>

                    <div class="h-1 rounded-full" :class="{
                        'bg-pamesa-whitemode': ( data[0].Id === '45' ||  data[0].Id === '1') && mode == 'whitemode',
                        'bg-pamesa-darkmode': ( data[0].Id === '45' ||  data[0].Id === '1') && mode == 'darkmode',
                        'bg-geotiles': data[0].Id === '28', 
                        'bg-prissmacer': data[0].Id === '30',
                        'bg-ecoceramic': data[0].Id === '31', 
                        'bg-dfont-whitemode': data[0].Id === '32' && mode == 'whitemode', 
                        'bg-dfont-darkmode': data[0].Id === '32' && mode == 'darkmode', 
                        'bg-tauceramica': data[0].Id === '36',
                        'bg-ascale': data[0].Id === '64'
                    }"></div>

                </div>

                <div class="flex-1">
                    <All model="Groupdimensions" :query="query" :immediate="true" v-slot="{data:data4,loading:loading4}">
                        <div v-if="!loading4" class="h-auto mt-2" @click="goToCompany(index, data[0].Name, data[0].IndustrialGroup)">
                        <double-chart :current="data[0].PreviewPercent" :prev="data[0].PastYearPercent"
                            v-if="data4[dimension].Name !== 'Margen' && data4[dimension].Name !== 'Precio medio' && !loading4" />
                        </div>
                    </All>
                    <div class="flex-1 flex flex-col justify-between px-2 pt-4" @click="goToCompany(index, data[0].Name, data[0].IndustrialGroup)">
                        <All model="Groupdimensions" :query="query" :immediate="true" v-slot="{data: data3,loading: loading3}">
                            <div v-if="!loading3" class="h-auto">
                                <div v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                                    class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                    <span class="text-sm text-darkgray">{{ $t('day') }}</span>
                                    <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{ data[0].Yesterday | reduceBigNumbers() }}
                                        <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                        <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                    </span>
                                </div>
                                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                    <span v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                                        class="text-sm text-darkgray">{{ $t('accumulated') }}</span>
                                    <span v-if="data3[dimension].Name === 'Precio medio'" class="text-sm text-darkgray">{{ $t('averagePrice') }}</span>
                                    <span v-if="data3[dimension].Name === 'Margen'" class="text-sm text-darkgray">{{ $t('margin') }}</span>
                                    <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{ data[0].Month | reduceBigNumbers() }}
                                        <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                        <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                    </span>
                                </div>
                                <div v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                                    class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                    <span class="text-sm text-darkgray">{{ $t('deliveryNotes' )}}</span>
                                    <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{ data[0].DeliveryNote | reduceBigNumbers() }}
                                        <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                        <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                    </span>
                                </div>
                                <div v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                                    class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                    <span class="text-sm text-darkgray">{{ $t('scope') }}</span>
                                    <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{ data[0].MonthPreview | reduceBigNumbers() }}
                                        <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                        <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                    </span>
                                </div>
                                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                    <span class="text-sm text-darkgray">{{ $t('real') }} {{ prevPeriod(infodata.LastSaleDate) }}</span>
                                    <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{ data[0].PastYear | reduceBigNumbers() }}
                                        <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                        <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                        <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                    </span>
                                </div>
                                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                    <span class="text-sm text-darkgray">{{ $t('variation') }} {{ periodo }}</span>
                                    <span v-if="data3[dimension].Name === 'Precio medio'" class="text-sm font-bold" :class="'text-dfont-'+mode">
                                        <span v-if="data[0].MonthVariation >= 0">+</span>{{ data[0].MonthVariation | reduceBigNumbers(2) }} €/m<sup>2</sup>
                                    </span>
                                    <span v-else-if="data3[dimension].Name === 'Margen'" class="text-sm font-bold" :class="'text-dfont-'+mode">
                                        <span v-if="data[0].MonthVariation >= 0">+</span>{{ data[0].MonthVariation | reduceBigNumbers(2) }}
                                    </span>
                                    <span v-else class="text-sm font-bold"  :class="'text-dfont-'+mode"><span v-if="data[0].MonthVariation >= 0">+</span>
                                        {{ data[0].MonthVariation | reduceBigNumbers(2) }} %
                                    </span>
                                </div>
                            </div>
                        </All>
                    </div>
                </div>

                <div v-if="permisions" class="h-auto">
                    <Request model="Grouptocompanymessage" action="create" :form="form" @success="onSuccess" @error="onError" v-slot="{ request , loading:loadingrequest }" class="w-full">
                        <div v-if="!loadingrequest" class="h-auto">
                            <div v-if="user.email === 'admin@mail.zeus.vision' && rol === 'Propiedad'" class="h-10 mb-2 flex flex-row justify-start items-center px-4">
                                <div class="h-8 flex-1 pr-2" @keypress.enter="request">
                                    <input ref="input" type="text" v-model="message" :placeholder="$t('writeMessage')"
                                        class="w-full h-full rounded-lg px-2 shadow-inner" :class="'bg-box-'+mode + ' text-dfont-'+mode">
                                </div>
                                <div @click="request" class="h-8 w-10 rounded-lg bg-red flex flex-row justify-center items-center">
                                    <i class="mdi mdi-send text-white"></i>
                                </div>
                            </div>
                        </div>
                    </Request>
                </div>

            </div>

            <div v-if="data[1]" class="h-auto ">

                <div class="h-auto flex flex-col" @click="goToCompany(index, data[1].Name, data[1].IndustrialGroup)">

                    <div class="h-16 flex flex-col px-4 py-2">

                        <div class="h-full flex flex-row">

                            <div class="h-full w-11/12 flex flex-col justify-center items-start truncate ">
                                <span class="text-xl font-semibold truncate" :class="{
                                    'text-pamesa-whitemode': (data[1].Id === '45' || data[1].Id === '1') && mode == 'whitemode',
                                    'text-pamesa-darkmode': (data[1].Id === '45' || data[1].Id === '1') && mode == 'darkmode',
                                    'text-geotiles': data[1].Id === '28', 
                                    'text-prissmacer': data[1].Id === '30',
                                    'text-ecoceramic': data[1].Id === '31', 
                                    'text-dfont-whitemode': data[1].Id === '32' && mode == 'whitemode', 
                                    'text-dfont-darkmode': data[1].Id === '32' && mode == 'darkmode', 
                                    'text-tauceramica': data[1].Id === '36'
                                }">{{ editName(data[1].Id) }} </span>
                            </div>

                            <div class="h-full w-1/12 flex flex-row justify-center items-center">
                                <i class="mdi mdi-arrow-right text-2xl" :class="{
                                    'text-pamesa-whitemode': (data[1].Id === '45' || data[1].Id === '1') && mode == 'whitemode',
                                    'text-pamesa-darkmode': (data[1].Id === '45' || data[1].Id === '1') && mode == 'darkmode',
                                    'text-geotiles': data[1].Id === '28', 
                                    'text-prissmacer': data[1].Id === '30',
                                    'text-ecoceramic': data[1].Id === '31', 
                                    'text-navarti': data[1].Id === '32', 
                                    'text-tauceramica': data[1].Id === '36'
                                }"></i>
                            </div>

                        </div>

                        <div class="h-1 rounded-full" :class="{
                            'bg-pamesa-whitemode': (data[1].Id === '45' || data[1].Id === '1') && mode == 'whitemode',
                            'bg-pamesa-darkmode': (data[1].Id === '45' || data[1].Id === '1') && mode == 'darkmode',
                            'bg-geotiles': data[1].Id === '28', 
                            'bg-prissmacer': data[1].Id === '30',
                            'bg-ecoceramic': data[1].Id === '31', 
                            'bg-navarti': data[1].Id === '32', 
                            'bg-tauceramica': data[1].Id === '36'
                        }"></div>

                        </div>


                    <div class="flex-1">
                        <All model="Groupdimensions" :query="query" :immediate="true" v-slot="{data:data4,loading:loading4}">
                            <div v-if="!loading4" class="h-auto mt-2">
                            <double-chart :current="data[1].PreviewPercent" :prev="data[1].PastYearPercent"
                                v-if="data4[dimension].Name !== 'Margen' && data4[dimension].Name !== 'Precio medio' && !loading4" />
                            </div>
                        </All>
                        <div class="flex-1 flex flex-col justify-between px-2 py-4">
                            <All model="Groupdimensions" :query="query" :immediate="true" v-slot="{data: data3,loading: loading3}">
                                <div v-if="!loading3" class="h-auto">
                                        <div v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                                            class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                        <span class="text-sm text-darkgray">{{ $t('day') }}</span>
                                        <span class="text-sm font-bold"  :class="'text-dfont-'+mode">{{ data[1].Yesterday | reduceBigNumbers() }}
                                            <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                            <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                        </span>
                                    </div>
                                    <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                        <span v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                                            class="text-sm text-darkgray">{{ $t('accumulated') }}</span>
                                        <span v-if="data3[dimension].Name === 'Precio medio'" class="text-sm text-darkgray">{{ $t('averagePrice') }}</span>
                                        <span v-if="data3[dimension].Name === 'Margen'" class="text-sm text-darkgray">{{$t('margin')}}</span>
                                        <span class="text-sm font-bold"  :class="'text-dfont-'+mode">{{ data[1].Month | reduceBigNumbers() }}
                                            <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                            <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                        </span>
                                    </div>
                                    <div v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                                        class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                        <span class="text-sm text-darkgray">{{ $t('deliveryNotes') }}</span>
                                        <span class="text-sm font-bold"  :class="'text-dfont-'+mode">{{ data[1].DeliveryNote | reduceBigNumbers() }}
                                            <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                            <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                        </span>
                                    </div>
                                    <div v-if="data3[dimension].Name === 'Facturación' || data3[dimension].Name === 'Metros cuadrados'"
                                        class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                        <span class="text-sm text-darkgray">{{ $t('scope') }}</span>
                                        <span class="text-sm font-bold"  :class="'text-dfont-'+mode">{{ data[1].MonthPreview | reduceBigNumbers() }}
                                            <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                            <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                        </span>
                                    </div>
                                    <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                        <span class="text-sm text-darkgray">Real {{prevPeriod(infodata.LastSaleDate)}}</span>
                                        <span class="text-sm font-bold"  :class="'text-dfont-'+mode">{{data[1].PastYear | reduceBigNumbers()}}
                                            <span v-if="data3[dimension].Name === 'Facturación'">€</span>
                                            <span v-if="data3[dimension].Name === 'Metros cuadrados'">m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Precio medio'">€/m<sup>2</sup></span>
                                            <span v-if="data3[dimension].Name === 'Margen'">%</span>
                                        </span>
                                    </div>
                                    <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                                        <span class="text-sm text-darkgray">{{ $t('variation') }} {{ periodo }}</span>
                                        <span v-if="data3[dimension].Name === 'Precio medio'" class="text-sm font-bold"  :class="'text-dfont-'+mode">
                                            <span v-if="data[1].MonthVariation >= 0">+</span>
                                            {{ data[1].MonthVariation | reduceBigNumbers(2) }} €/m<sup>2</sup>
                                        </span>
                                        <span v-else-if="data3[dimension].Name === 'Margen'" class="text-sm font-bold"  :class="'text-dfont-'+mode">
                                            <span v-if="data[1].MonthVariation >= 0">+</span>
                                            {{ data[1].MonthVariation | reduceBigNumbers(2) }}
                                        </span>
                                        <span v-else class="text-sm font-bold"  :class="'text-dfont-'+mode">
                                            <span v-if="data[1].MonthVariation >= 0">+</span>
                                            {{ data[1].MonthVariation | reduceBigNumbers(2) }} %
                                        </span>
                                    </div>
                                </div>
                            </All>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div> -->

</template>

<script>
import { All, Request } from '@/api/components';
import { state } from '@/store';
import doubleChart from '@/components/doubleChart.vue';

export default {
    props: ['data', 'infodata', 'index'],
    components: {
        All,
        Request,
        doubleChart
    },
    data() {
        return {
            message: null
        }
    },
    methods:{
        prevPeriod() { return this.$moment().subtract(1, 'years').format("YYYY") },
        goToCompany(companyid, companyname, industrialgroup) {
            if (this.rol === 'Propiedad' || this.data[0].Id == this.user.relation[0].company.IdLink) { this.$emit('goToCompany', companyid, companyname, industrialgroup) }
            else {  }
        },
        onSuccess() {
            this.message = null
            this.$message({
            message: this.$t("messageSentSuccessfully"),
            type: 'success'
            });
        },
        onError(error) { this.$message.error(this.$t("theMessageCouldNotBeSent")) },
        editName(name){
            if(name == 45){
                return 'PAMESA CERAMICA GRUPO IND'
            } else {

                return ''
            }
        }
    },
    computed: {
        mode(){
            return state.mode
        },
        periodo() {
            switch (state.period) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("monthly")
                    break;
                case 'year':
                    return this.$t("monthly")
                    break;
            }
        },
        rol() {return state.user.rol.Name },
        query() { return { period: state.period, month: state.month } },
        dimension() { return state.dimensionGroup },
        companySelected() { return state.companySelected },
        user() { return state.user },
        form() {
            return {
                message: this.message,
                Company: this.index,
            }
        },
        permisions() {
            if (this.rol === 'Propiedad') { return true }
            else { return false }
        }
    }
}
</script>